<template>
  <div class="admin-email-invites">
    <loading-screen :is-loading="isSaving"></loading-screen>
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="goBack"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Users - Email New Users
      </h1>
    </div>

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <!-- No users to show -->
    <a-alert
      v-if="!isLoading && usersToShow.length === 0"
      type="info"
      message="No users to show"
    />
    <!-- / No users to show -->

    <!-- Has users -->
    <div class="has-users" v-if="!isLoading">
      <div class="form">
        <div class="toolbar">
          <div class="left">
            <h3>Select Recipients</h3>
          </div>
          <div class="right">
            <a-button @click.prevent="selectAll"> Select All </a-button>
            <a-button class="button-margin-left" @click.prevent="deselectAll">
              Deselect All
            </a-button>
          </div>
        </div>

        <div class="checkbox-wrapper">
          <div class="user-row" v-for="(user, i) in usersToShow" :key="i">
            <a-checkbox
              :checked="isPresenceSelected(user)"
              @change="togglePresence(user)"
            >
              {{ user.displayName }} ({{ user.email }})
            </a-checkbox>
          </div>
        </div>
      </div>

      <div class="form">
        <div class="toolbar">
          <h3 class="h3-mb">Delay Email?</h3>
        </div>
        <a-checkbox v-model="scheduleSend"> Schedule email sending </a-checkbox>

        <div v-if="scheduleSend" class="schedule-send">
          <!-- Picker -->
          <a-row :gutter="100">
            <a-col :span="8">
              <a-form-item label="Date">
                <a-date-picker style="width: 100%" v-model="scheduledDate" />
              </a-form-item>
            </a-col>
            <a-col :span="16">
              <a-form-item :label="`Time (${formatter(scheduledTime)})`">
                <a-slider
                  v-model="scheduledTime"
                  :tip-formatter="(value) => formatter(value)"
                  :min="0"
                  :max="1440"
                  :step="15"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <!-- / Picker -->
        </div>
      </div>

      <a-button
        :disabled="!canSave"
        @click.prevent="save"
        class="btn-rounded save-button"
        size="large"
        type="primary"
        >Send Invite</a-button
      >
    </div>
    <!-- / Has users -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import organisations from "../../../../api/organisations";
import presences from "../../../../api/presences";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import moment from "moment";
export default {
  name: "EmailInvites",
  data() {
    return {
      isLoading: false,
      isSaving: false,

      users: [],
      selectedIds: [],

      scheduleSend: false,

      scheduledDate: moment(),
      scheduledTime: (moment().hour() * 60) + moment().minute() + 5,
    };
  },
  components: { LoadingScreen },
  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId",
    }),

    canSave() {
      return this.selectedIds.length;
    },

    usersToShow() {
      let filtered = _.filter(this.users, (user) => {
        return !user.lastAccess;
      });
      return _.orderBy(filtered, "displayName");
    },
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    this.loadUsers();
  },
  methods: {
    goBack() {
      this.$router.push("/admin/users/manage");
    },

    formatter(value) {
      let hr = Math.floor(Number(value) / 60);
      let min = value % 60;

      return String(hr).padStart(2, "0") + ":" + String(min).padStart(2, "0");
    },

    async loadUsers() {
      let vm = this;

      vm.isLoading = true;
      vm.users = [];

      try {
        vm.users = await organisations
          .getMembershipForOrg(vm.tenantId, vm.selectedOrganisation.id)
          .then((r) => r.data);
      } catch (e) {
        console.log(e);
        vm.$message.error("Error loading users");
      }

      vm.isLoading = false;
    },

    isPresenceSelected(presence) {
      return this.selectedIds.includes(presence.id);
    },

    togglePresence(presence) {
      this.selectedIds = _.xor(this.selectedIds, [presence.id]);
    },

    selectAll() {
      this.selectedIds = _.map(this.users, "id");
    },

    deselectAll() {
      this.selectedIds = [];
    },

    save() {
      let vm = this;

      if (!vm.canSave) {
        return false;
      }

      let afterTimestamp = null;
      if (this.scheduleSend) {
        let startHour = Math.floor(this.scheduledTime / 60);
        let startMinute = this.scheduledTime % 60;
        let startDateTime = this.scheduledDate
          .clone()
          .set("hour", startHour)
          .set("minute", startMinute);
        if (startDateTime.isBefore(moment())) {
          vm.$message.error('Scheduled send time cannot be in the past!');
          return false;
        }
        afterTimestamp = startDateTime.format('X');
      }

      vm.isSaving = true;
      presences
        .sendNewPresenceEmails(
          this.tenantId,
          this.selectedOrganisation.id,
          this.selectedIds,
          afterTimestamp
        )
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Emails sent successfully");
          vm.goBack();
        })
        .catch((e) => {
          console.log(e);
          vm.$message.error("Error sending emails. Please try again.");
          vm.isSaving = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  padding: 40px;
  text-align: center;
}

.button-margin-left {
  margin-left: 15px;
}

.form {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;
  margin-bottom: 20px;
}

.toolbar {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
  }
}

.checkbox-wrapper {
  margin-top: 20px;
}

.h3-mb {
  margin-bottom: 25px;
}

.user-row {
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
}

.schedule-send {
  margin-top: 25px;
}
</style>